/* Helper classes */
.flexDiv {
    display: flex !important;
}
.flexColumn {
    flex-direction: column;
}
.inlineFlexGrid {
    .column {
        display: inline-flex;
    }
}
.sticky {
    position: sticky;
    left: 0;
}
.shrink {
    flex-grow: 0 !important;
    flex-shrink: 1;
}
.stretch-col {
    flex-grow: 1;
}
.grow {
    flex-grow: 1 !important;
}

/* Alignment */
.justifyContent {
    justify-content: space-between;
}
.vertSelfStretch,
.SelfStretch {
    align-self: stretch !important;
}
.alignCenter {
    align-items: center;
}
.ui.vertical.grid {
    flex-direction: column;
    align-items: stretch;
}
.floatRight {
    float: right;
}
.view-more {
    margin: 1.5rem auto 0 auto !important;
    display: block !important;
    width: 50% !important;
}
.statistic {
    @media (max-width: 819px) {
        .report-link {
            display: block !important;
            width: 100px !important;
            margin: 1rem auto 0 auto !important;
            padding: 0.78571429em 0.5em 0.78571429em;
            color: #ffffff;
            text-transform: none;
            text-shadow: none;
            font-weight: bold;
            line-height: 1em;
            font-style: normal;
            text-align: center;
            text-decoration: none;
            min-height: 1em;
            outline: none;
            border: none;
            border-radius: 0.28571429rem;
            vertical-align: baseline;
            background-color: #00b06d;
            background-image: none;
            user-select: none;
            &:after {
                display: none;
            }
        }
    }
}
/* Font Size */
.font1rem {
    font-size: 1rem !important;
}

/* Padding */
.paddedDot5rem {
    padding: 0.5rem !important;
}
.padded1rem {
    padding: 1rem !important;
}
.padded2rem {
    padding: 2rem !important;
}
.paddedLR2rem {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
}
.paddingV1rem {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}
.paddingV2rem {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
}
.paddingT2rem {
    padding-top: 2rem !important;
}
.paddingT2B1rem {
    padding-top: 2rem !important;
    padding-bottom: 1rem !important;
}
.padding0 {
    padding: 0 !important;
}
.paddingV0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.paddingB0 {
    padding-bottom: 0 !important;
}
.paddingH0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
@media (max-width: 810px) {
    .mobileNoPad {
        padding-top: 0rem !important;
        padding-bottom: 0rem !important;
    }
    .mobileViewportPadding {
        padding-bottom: 6rem !important;
    }
    .mobilePadB100 {
        padding-bottom: 100px !important;
    }
}

/* Margin */
.marginDot5rem {
    margin: 0.5rem !important;
}
.marginV1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}
.marginV0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.marginH0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.marginBDot5rem {
    margin-bottom: 0.5rem !important;
}
.marginB1 {
    margin-bottom: 1rem !important;
}
.margin0 {
    margin: 0 !important;
}

/* Outline none */
.noBorder {
    border: 0 !important;
    box-shadow: none !important;
}
.noBGcolor {
    background-color: transparent !important;
}

/* Background none */
.noBG {
    background: none !important;
    background-color: transparent !important;

    &.ui.button.icon:active,
    &.ui.button.icon:hover {
        background: none !important;
        background-color: transparent !important;
    }
}

/* Hover Cursor */
.hover-cursor {
    cursor: pointer;
}

/* Display types */
.inlineBlock {
    display: inline-block !important;
}

/* Scrollbars */
@media (max-width: 819px) {
    ::-webkit-scrollbar {
        width: 4px !important;
        height: 4px !important;
        background: #f5f5f5;
    }
    ::-webkit-scrollbar-track {
        border-radius: 1rem;
    }
    ::-webkit-scrollbar-thumb {
        width: 4px !important;
        height: 4px !important;
        background: #00b06d !important;
    }
}
