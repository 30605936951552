/* Patient Profile */
.reportCard {
    padding-top: 2rem;

    .grid .row {
        border-bottom: 5px solid #fff;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .ui.statistic {
        .value {
            font-size: 3.5rem;
            letter-spacing: -1px;
            margin-bottom: 0.25rem;
        }
        &.aa-score {
            .value {
                font-size: 4rem !important;
                @media (min-width: 1240px) {
                    font-size: 5rem !important;
                }
                @media (min-width: 1420px) {
                    font-size: 6rem !important;
                }
            }
            .label {
                font-size: 1.5rem !important;
                line-height: 1.1;
            }
        }
    }
    /* Guage and Rating chart styles */
    .guageCrop {
        position: relative;
        padding-bottom: 45%;

        .recharts-responsive-container {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .ratingStats {
        display: flex;
        width: 100%;
        padding: 0 1rem;
        margin: 0 auto;
        justify-content: space-between;
        @media (min-width: 1060px) {
            padding: 0 2rem;
            width: 90%;
        }
        @media (min-width: 1440px) {
            width: 300px;
        }
        @media (min-width: 820px) and (max-width: 1060px) {
            padding: 0;
            .statistic {
                .value {
                    font-size: 1.25rem !important;
                }
            }
        }
        .statistic {
            .value {
                letter-spacing: normal !important;
            }
        }
    }
    .two.column.row {
        .column {
            align-items: center;
        }
    }
    .ui.horizontal.statistics {
        align-items: center;
        .statistic {
            & > .value {
                font-size: 3rem !important;
                min-width: 80px;
                text-align: right;
                padding-right: 1rem;
            }
        }
    }
}
/* Specific Acuity Score styles -- needs cleanup later */
.reportCard {
    padding-top: 0;
}
.reportCard .grid .row {
    border-bottom: 5px solid #fff;
    padding-left: 2rem;
    padding-right: 2rem;
}
.reportCard .grid .row:first-child {
    padding-bottom: 0;
    align-items: stretch !important;
}
.reportCard .grid .row .column {
    padding-top: 1rem;
    padding-bottom: 1rem;
    /* flex: 1 1 auto; */
}
.reportCard .grid .row:first-child .column:first-child {
    border-right: 5px solid #fff;
}
.ui.statistic.aa-score .value {
    font-size: 6rem !important;
}
.ui.statistic.aa-score .label {
    font-size: 1.5rem !important;
    line-height: 1.1;
}
/* Popup */
.popup {
    ul {
        padding-left: 1.25rem;
        li {
            margin-bottom: 1rem;
        }
    }
}
/* Population Percentile Progress bar */
.ui.progress.percent {
    background-image: linear-gradient(90deg, $secondary, $primary);
    border-radius: 2rem;

    &:before,
    &:after {
        position: absolute;
        bottom: -1.75rem;
        font-size: 1rem;
        color: #999;
    }
    &:before {
        content: '0';
        left: 0;
    }
    &:after {
        content: '100%';
        right: 0;
    }

    .bar {
        border-radius: 0;
        background: none;
        &:after {
            content: '';
            position: absolute;
            top: -0.33rem;
            right: 0;
            display: block;
            height: 33px;
            width: 3px;
            border-radius: 2px;
            background: #000;
        }
        & > .progress {
            color: #000;
            bottom: 2.25rem;
            right: -0.8rem;
            left: auto;
            top: auto;
        }
    }
}

.timelineHeader {
}
.patient-timeline {
    justify-content: center;
    @media (max-width: 819px) {
        padding-bottom: 80px !important;
    }
}
.no-picker {
    .flowing.popup.visible {
        display: none !important;
    }
}
