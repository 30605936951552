/* Dividers */
.labeledDivider {
  @media (min-width:820px) {
    margin-bottom: 3rem !important;
  }
  
  &:before {
    background-position: right 0 top 50% !important;
  }
  &:after {
    background-position: left 0 top 50% !important;
  }
  span {
    font-size: .8rem;
    color: #7C7C7C;
    background-color: #F0F0F0;
    padding: .5rem 1rem;
    border-radius: 2px;
  }
}
.ui.divider {
  &.horizontal {
    &:before {
      background-position: right .5em top 50% !important;
    }
    &:after {
      background-position: left .5em top 50% !important;
    }
  }
}
