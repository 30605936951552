/* Feed Styles */
.listView .patient-timeline {
    .ui.feed {
        width: 100%;
        padding-bottom: 1rem;
        padding-left: 0;
        padding-right: 0;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 1.5rem;
            right: auto;
            transform: translate(-50%, 0);
            display: block;
            height: 100%;
            width: 5px;
            background-color: #f5f5f5;
            z-index: 0;
        }

        & > .event {
            position: relative;
            z-index: 1;
            width: 100%;
            border-bottom: 2px solid #f2f2f2;
            cursor: pointer;

            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                right: auto;
                transform: translate(0, -50%);
                display: block;
                height: 14px;
                width: 14px;
                background-color: #00b06d;
                border-radius: 50%;
                z-index: 1;
            }
            .event-date {
                .day {
                    font-size: 1.75rem;
                    padding-right: 0.25rem;
                    @media (min-width: 820px) {
                        font-size: 2.25rem;
                        padding-right: 0.25rem;
                    }
                }
            }
            @media (min-width: 820px) {
                &:hover {
                    .event-date {
                        background-color: transparent;
                        color: #00b06d;
                    }
                }
            }
            .ui.mini.label {
                align-self: flex-start;
                margin-top: 0 !important;
                margin-left: 0.5rem;
                white-space: nowrap;
                padding: 0.35em 0.5em;
                position: absolute;
                left: 82px;

                span {
                    display: none;
                }
            }
            &:nth-child(odd),
            &:nth-child(even) {
                margin-right: 0;
                margin-left: 0;
                padding-left: 1.5rem;
                & > .label {
                    order: 1;
                    position: relative;
                    left: 0;
                    right: auto;
                    img {
                        width: 26px;
                    }
                }
                & > .content {
                    order: 2;
                    &:after {
                        content: '';
                        position: absolute;
                        display: none;
                    }
                    .event-date {
                        order: 1;
                        border-radius: 0;
                        flex-direction: row;
                        align-items: center;
                        padding-left: 0;
                        .day {
                            margin-bottom: 0;
                            line-height: 2rem;
                        }
                        .month,
                        .year {
                            font-size: 0.8rem;
                            line-height: 0.8rem;
                            @media (min-width: 820px) {
                                font-size: 0.95rem;
                                line-height: 1rem;
                            }
                        }
                    }
                    .event-content {
                        position: relative;
                        order: 2;
                        flex-direction: row;
                        padding: 1rem 0 1rem 1rem;
                        @media (max-width: 819px) {
                            justify-content: space-between;
                        }
                        &:before {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 0;
                            transform: translate(0, -50%);
                            width: 2px;
                            height: 50%;
                            background-color: #ddd;
                            display: block;
                        }
                        h3 {
                            line-height: 1.2rem;
                            @media (max-width: 819px) {
                                font-size: 1rem;
                            }
                        }
                        h5 {
                            margin-bottom: 0 !important;
                            font-size: 0.7rem;
                            text-transform: uppercase;
                            @media (min-width: 820px) {
                                font-size: 0.8rem;
                            }
                        }
                        .event-content__button {
                            visibility: visible;
                            height: auto;
                            width: auto;
                            padding-left: 1rem;
                            margin-left: 1rem;
                            border-left: 2px solid #ddd;
                            .ui.button {
                                align-self: center;
                            }
                        }
                    }
                }
                &:after {
                    content: '';
                    left: 0;
                    right: auto;
                    background-color: #00b06d;
                }
            }

            & > .label {
                order: 1;
                align-self: center;
                position: relative;
                left: 0;
                right: auto;
                img {
                    border-radius: 0;
                }
            }

            & > .content {
                order: 2;
                position: relative;
                display: flex;
                flex: 0 1 auto;
                background-color: transparent;
                width: 100%;
                padding: 0;
                margin: 0 !important;
                border-radius: 0.5rem;
                z-index: 1;
                &:after {
                    content: '';
                    display: none;
                }
                .event-date {
                    border-radius: 0;
                    background-color: transparent;
                }
                * {
                    display: inline-flex;
                }
            }
        }
    }
}

.patient-timeline {
    .ui.feed {
        width: 100%;
        padding-bottom: 1rem;
        padding-left: 0;
        padding-right: 0;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: auto;
            right: 18%;
            transform: translate(-50%, 0);
            display: block;
            height: 100%;
            width: 5px;
            background-color: #f5f5f5;
            z-index: 0;
        }
        @media (min-width: 820px) {
            width: 650px;
            margin-left: auto;
            margin-right: auto;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 50%;
                right: auto;
                transform: translate(-50%, 0);
                display: block;
                height: 100%;
                width: 5px;
                background-color: #f5f5f5;
                z-index: 0;
            }
        }

        & > .event {
            position: relative;
            z-index: 1;
            width: 70%;
            cursor: pointer;

            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: auto;
                right: -21%;
                transform: translate(0, -50%);
                display: block;
                height: 14px;
                width: 14px;
                background-color: #00b06d;
                border-radius: 50%;
                z-index: 1;
            }

            & > .label {
                order: 1;
                align-self: center;
                position: absolute;
                left: auto;
                right: -40%;
                img {
                    border-radius: 0;
                }
            }

            & > .content {
                order: 2;
                position: relative;
                display: flex;
                flex: 0 1 auto;
                background-color: #f5f5f5;
                width: 100%;
                padding: 0;
                margin: 0 !important;
                border-radius: 0.5rem;
                z-index: 1;
                &:after {
                    content: '';
                    position: absolute;
                    top: 30px;
                    left: auto;
                    right: -40px;
                    display: block;
                    width: 20px;
                    height: 40px;
                    border-top: 20px solid transparent;
                    border-right: 20px solid transparent;
                    border-bottom: 20px solid transparent;
                    border-left: 20px solid #f5f5f5;
                    z-index: 2;
                }
                .event-date {
                    border-radius: 0.5rem 0 0 0.5rem;
                }
                * {
                    display: inline-flex;
                }
                .ui.mini.label {
                    align-self: flex-start;
                    text-transform: uppercase;
                    margin-top: 0.5rem;
                }
            }
            @media (min-width: 820px) {
                width: 45%;
                & > .content {
                    width: 260px;
                }
                &:nth-child(odd) {
                    margin-right: auto;
                    & > .label {
                        order: 2;
                        position: absolute;
                        left: auto;
                        right: -35%;
                    }
                    & > .content {
                        order: 1;
                        &:after {
                            content: '';
                            position: absolute;
                            left: auto;
                            right: -40px;
                            border-top: 20px solid transparent;
                            border-right: 20px solid transparent;
                            border-bottom: 20px solid transparent;
                            border-left: 20px solid #f5f5f5;
                        }
                        .event-date {
                            border-radius: 0.5rem 0 0 0.5rem;
                        }
                    }
                    &:after {
                        content: '';
                        left: auto;
                        right: -13.5%;
                        background-color: #00b06d;
                    }
                }

                &:nth-child(even) {
                    margin-left: auto;
                    justify-content: flex-end;
                    &:after {
                        content: '';
                        left: -13.5%;
                        right: auto;
                        background-color: #00b06d;
                    }
                    & > .label {
                        order: 1;
                        position: absolute;
                        left: -35%;
                        right: auto;
                    }
                    & > .content {
                        &:after {
                            content: '';
                            position: absolute;
                            top: 30px;
                            left: -40px;
                            right: auto;
                            display: block;
                            width: 20px;
                            height: 40px;
                            border-top: 20px solid transparent;
                            border-right: 20px solid #f5f5f5;
                            border-bottom: 20px solid transparent;
                            border-left: 20px solid transparent;
                            z-index: 2;
                        }
                        .event-date {
                            order: 2;
                            border-radius: 0 0.5rem 0.5rem 0;
                        }
                        .event-content {
                            order: 1;
                        }
                    }
                }
            }
        }
    }
}
