/* Main Filter */
.ui.dropdown {
  &.mainFilter {
    .menu {
      left: auto;
      right:0;
      width: auto;
    }
  }

}
