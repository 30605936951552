/* Form customizations */
.ui.checkbox {
    input {
        & ~ label {
            color: inherit !important;
        }
        &:focus {
            & ~ label {
                color: inherit !important;
            }
            &:checked {
                & ~ label {
                    color: inherit !important;
                }
            }
        }
        &:checked {
            & ~ label {
                color: inherit !important;
            }
        }
    }
}

.ui.toggle.checkbox {
    input {
        & ~ label {
            color: inherit !important;
        }
        &:focus {
            & ~ label {
                color: inherit !important;
            }
            &:checked {
                & ~ label {
                    color: inherit !important;
                }
            }
        }
        &:checked {
            & ~ label {
                color: inherit !important;
            }
        }
    }
    &.right-aligned-toggle {
        @media (max-width: 819px) {
            display: block;
            input {
                & ~ label {
                    width: 100%;
                    padding-left: 0;
                    &:before {
                        left: auto;
                        right: 0px;
                    }
                    &:after {
                        left: auto;
                        right: 2.15rem;
                        transition: background 0.3s ease, right 0.3s ease !important;
                    }
                }
                &:checked {
                    & ~ label {
                        color: inherit !important;
                        &:after {
                            left: auto !important;
                            right: 0;
                        }
                    }
                }
            }
        }
    }
}
.multiple.selection.dropdown {
    .ui.label {
        background: #00b06d;
        box-shadow: none;
        color: #fff;
    }
}

.accordion {
    .ui.checkbox {
        margin-bottom: 0.5rem;
    }
}
@media (min-width: 820px) {
    .paddedNoLabel {
        padding-top: 1.7rem;
    }
}
.ui.button {
    text-rendering: geometricPrecision;
}

/* File input */
.inputfile {
    left: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    cursor: pointer;
}
