/* Accordion */
.ui.accordion.styled {
  border-radius: 0!important;
  background: none;
  -webkit-box-shadow: none!important;
  box-shadow: none!important;

  .title {
    font-size: 1.2rem !important;
    padding: 0.75em 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .icon {
      font-size: 1.5rem !important;
      line-height: 1em;
    }
    &.active {
      color: #000 !important;
      .icon {
        transform: rotate(-180deg);
      }
    }
  }
}
