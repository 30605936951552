/* Date Styles for Timeline */
.event {
    @media (min-width: 820px) {
        &:hover {
            .event-date {
                background-color: #00b06d;
                color: #fff;
            }
        }
    }
}
.event-date {
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem;
    background-color: #e5e5e5;
    transition: all 0.1s ease;
    div:first-child {
    }
    div:last-child {
        display: flex;
        flex-direction: column;
    }
    .day {
        font-size: 2.5rem;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 0.5rem;
    }
    .month,
    .year {
        font-size: 1.25rem;
        font-weight: 600;
        text-transform: uppercase;
    }
}
.event-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 1rem;
    .event-content__detail {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        @media (min-width: 820px) {
            min-width: 150px;
        }
        h3 {
            margin: 0 !important;
        }
        h5 {
            margin-bottom: 0 !important;
        }
    }
    .event-content__button {
        visibility: hidden;
        height: 0;
        width: 0;
    }
}

.event-modal__header {
    display: flex;
    .event-date {
        display: inline-flex;
        flex-direction: column;
        background-color: transparent;
        padding: 0;
        margin-right: 2rem;
        .day,
        .month,
        .year {
            line-height: 1.25rem;
        }
    }
    h2.ui.header {
        margin: -0.5rem 0 0;
    }
    .event-modal__title {
        display: inline-flex;
        flex-direction: column;
        .ui.label {
            text-transform: uppercase;
            margin-top: 0.5rem;
        }
    }
}
.event-modal__cost {
    position: relative;
    .ui.header {
        color: #00b06d;
        font-size: 1.75rem !important;
        line-height: 1.2;
        span {
            color: #1d1d1d;
            font-size: 1rem;
            text-transform: uppercase;
            display: block;
        }
    }
}
