/* Header styles */
.ui.header {
    &:first-child {
        margin-top: 0;
    }
    .icon {
        &:hover {
            background: none !important;
        }
        &.timelineButton {
            display: inline-block;
            margin-left: 0.75rem;
            vertical-align: bottom;
            svg {
                margin: 0 0.25rem;
            }
            &.tl-active {
                svg.iconTimeline g {
                    stroke: #00b06d !important;
                }
                svg.iconList g {
                    stroke: #9b9b9b !important;
                }
            }
            &.list-active {
                svg.iconTimeline g {
                    stroke: #9b9b9b !important;
                }
                svg.iconList g {
                    stroke: #00b06d !important;
                }
            }
        }
    }
}
.ui.dividing.header {
    width: 100%;
    & + .ui.header {
        margin-top: 0;
    }
}
h1.header {
    color: #00b06d !important;
    font-size: 1.1rem !important;
    @media (min-width: 820px) {
        font-size: 1.25rem !important;
    }
    font-weight: 900 !important;
    text-transform: uppercase !important;
    span {
        display: block;
        margin-left: 0;
        margin-top: 0.5rem;
        text-transform: none;
        font-weight: 600 !important;
        @media (min-width: 820px) {
            display: inline;
            margin-left: 1rem;
            margin-top: 0;
        }
        small {
            font-size: 1rem;
            color: #777;
        }
    }
}
.ui.header.headerLink {
    cursor: pointer;
}
h2.header,
.ui.header.headerLink {
    color: #000000;
    font-family: 'Lato', sans-serif !important;
    font-size: 1.5rem !important;
    font-weight: 700;
    flex-grow: 0 !important;
    flex-shrink: 1;
    display: inline-block;

    @media (min-width: 820px) {
        /*margin-bottom: 2rem !important;*/
    }
    span {
        font-size: 0.9rem;
        color: #00b06d;
        display: inline-block;
        margin-left: 1rem;
        i {
            font-style: normal;
            color: #5a5a5a;
            display: inline-block;
            margin-right: 0.25rem;
        }
    }
}
.reportGrid {
    h2.header,
    .ui.header.headerLink {
        margin-bottom: 2rem !important;
        @media (max-width: 819px) {
            display: block;
            text-align: center;
            margin-left: auto !important;
            margin-right: auto !important;
            span {
                display: none;
            }
        }
    }
}
div.ui.date i {
    font-style: normal;
    color: #5a5a5a;
    display: inline-block;
    margin-right: 0.25rem;
}
div.ui.modal.active {
    h2.header,
    .ui.header.headerLink {
        @media (min-width: 820px) {
            margin-bottom: 0 !important;
        }
    }
}
h2.ui.header.patientName {
    @media (min-width: 820px) {
        font-size: 2rem !important;
    }
    margin-bottom: 0 !important;
    line-height: 1;
}
.ui.header.headerLink {
    &:hover {
        color: #00b06d;
    }
}

/* Page headers with filters */
.pageHeader {
    position: relative;
    margin-bottom: 0;
    background-color: #f5f5f5;
    padding: 0 !important;
    .column {
        padding: 0.5rem 1rem !important;
    }
    &.activityHeader {
        @media (max-width: 819px) {
            h2.header {
                color: #00b06d;
                font-size: 1rem !important;
            }
            .ui.dropdown.mainFilter {
                .text {
                    font-size: 0.8rem;
                    text-transform: uppercase;
                    & + .icon {
                        display: none !important;
                    }
                }
            }
        }
    }
    &.ruled {
        &:before {
            content: '';
            position: absolute;
            display: block;
            height: 1px;
            top: 0;
            bottom: auto;
            left: 1rem;
            right: 1rem;
            background-color: rgba(34, 36, 38, 0.22);
        }
        &:after {
            content: '';
            position: absolute;
            display: block;
            height: 1px;
            top: auto;
            bottom: 0;
            left: 1rem;
            right: 1rem;
            background-color: rgba(34, 36, 38, 0.22);
        }
    }

    @media (min-width: 820px) {
        background-color: transparent;
        padding-top: 2rem !important;
        height: 80px;
        &:after {
            content: '';
            position: absolute;
            display: block;
            height: 1px;
            top: auto;
            bottom: 0;
            left: 1rem;
            right: 1rem;
            background-color: rgba(34, 36, 38, 0.22);
        }
    }
    .form {
        .inline.fields {
            .field {
                .selection {
                    width: 100% !important;
                }
            }
        }
    }
}
@media (max-width: 819px) {
    /* lovely ultra specificity */
    .ui.stackable.grid {
        & > .row.pageHeader {
            & > .wide.column {
                padding: 0.5rem 1rem !important;
            }
        }
    }
}

/* Styled Text */
.green-text {
    color: #00b06d;
    font-weight: 700;
}
.report-link {
    position: relative;
    font-size: 0.9rem;
    font-weight: 600;
    padding-top: 0.25rem;
    margin-top: 0.5rem;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: block;
        margin: 0 auto;
        width: 30px;
        height: 1px;
        background-color: #000;
    }
}
/* Text Alignment */
.text-align-center {
    text-align: center;
}

/* Forms */
.ui.form:after {
    content: '';
    display: table;
    clear: both;
}

/* Stats */
.ui {
    .statistics {
        .statistic {
            & > .value.value {
                font-weight: 700;
            }
        }
        &.horizontal {
            .statistic {
                margin: 0.25em 0em;
            }
        }
    }
    & > .value.value {
        font-weight: 700;
    }
}

/* Lists */
ul.ui.list li,
ol.ui.list li,
.ui.list > .item,
.ui.list .list > .item {
    padding: 0.5rem 0;
}

/* Forms */
.ui.checkbox input:checked ~ .box:before,
.ui.checkbox input:checked ~ label:before {
    background: #00b06d;
    border-color: rgba(255, 255, 255, 0.36);
}
.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
    color: #fff;
}
