/* Login */
.login-page {
  padding: 0!important;
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  @media (min-width:820px){
    align-items: center;
  }
  text-align: center;
  background-color: #F0F0F0;
  width: 100% !important;

  .login-form {
    width: 100%;
    margin: 0 auto !important;
    .ui.segment {
      overflow: hidden;
      border-radius: 0;
      @media (min-width:820px){
        border-radius: .7rem;
      }
      border: 0;
      box-shadow: 0px 0px 26px 0 rgba(0, 0, 0, 0.15)
    }
    @media (max-width:819px){
      display: flex;
      flex-direction: column;
      align-items: stretch;
      .ui.segment {
        flex-grow: 1;
      }
    }
    @media (min-width:820px){
      width: 376px;
    }
    .ui.divider {
      margin-bottom: 2rem;
      text-transform: none;
      font-size: 1.45rem;
      font-weight: 400;
      letter-spacing: -.02rem;
    }
    p {
      a {
        color: #000;
      }
    }
    .form {
      .field {
        .input {
          margin-bottom: 0;
          .icon {
            color: #999;
          }
        }
      }
    }
  }

  .logo {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
    margin-bottom: 30px !important;
    color: #fff;
    background-color: #00B06D;
    p {
      text-transform: uppercase;
      font-size: 1.15rem;
      font-weight: 900;
      letter-spacing: 3px;
      margin: 1rem 0 0 !important;
    }
  }
  .clientLogo {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  img {

  }

  h1 {
    line-height: 5px;
    padding-top: 40px;
    color: #00B06D;
  }

  p {
    margin-bottom: 40px;
  }

  .ui.error.message p {
    padding: 0;
  }
}
