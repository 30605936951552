.ui.table {
  &.very.basic {
    tr {
      th {
        font-size: 0.85rem;
      }
      td {
        border-top: 0;
      }
    }
  }
  &.sortable {
    thead {
      th {
        border-left: 0 !important;
        &:hover,
        &.sorted {
          background: none !important;
        }
      }
    }
  }
}
.react-collapsible-theme {
  .brand-primary-light {
    color: #00B06D !important;
  }
  .react-collapsible {
    td,thead th {
      min-width: 2rem !important;
    }
    .child-label {
      margin-left: 0 !important;
      flex-basis: 50% !important;
      text-transform: none !important;
      letter-spacing: normal !important;
      @media (max-width: 819px) {
        font-size: .85rem !important;
      }
    }
    tr {
      position: relative;
      z-index: 1;
      td {
        .toggleIcon {
          position: absolute;
          top: auto;
          right: 1rem;
          z-index: 2;
        }
      }
      &.expanded-row {
        td {
          background-color: #f5f5f5;
          padding: .8rem;
        }
      }
    }
    th {
      font-size: 0.85rem;
      text-transform: none;
      letter-spacing: normal;
      &.clickable {
        span {
          svg {
            width: 15px;
          }
        }
      }
    }
    td {
      font-size: 1rem !important;
    }
  }
}
