/* Lists */
.ui.list>.item {
  &>.image+.content,
  &>img.image {
    vertical-align: middle;
  }
}

@media (max-width:819px) {
  .patientInfo {
    .column:nth-child(1):after {
      content: '';
      display:block;
      padding: .25rem 0;
      margin-bottom:1rem;
      border-bottom:1px solid #ddd;
    }
    .list {
      .item {
        display:flex !important;
        flex-direction: row !important;
        justify-content: space-between !important;
        align-items: stretch !important;
        padding: 0;

        &:after {
          display:none;
        }

        & > .header,
        & > .description {
          margin-bottom:.5rem;
        }
      }
    }
  }
}
