.side-nav {
    width: 86px !important;
    padding-left: 0 !important;
    .ui.vertical.menu {
        background-color: #f1f1f1 !important;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        .item {
            position: relative;
            border-radius: 0;
            padding: 3em 1.14285714em 3em;
            &:before {
                display: none;
            }
            &:hover {
                background: none;
                svg {
                    g {
                        path,
                        circle {
                            stroke: #727477;
                        }
                    }
                }
            }
            &.active {
                background: none !important;
                svg {
                    g {
                        path,
                        circle {
                            stroke: #00b06d;
                        }
                    }
                }
                @media (min-width: 820px) {
                    &:after {
                        content: '';
                        position: absolute;
                        top: 2.5rem;
                        right: -0.5rem;
                        display: block;
                        height: 1.5rem;
                        width: 1.5rem;
                        border-right: 1.5rem solid #fff;
                        border-top: 1.5rem solid transparent;
                        border-bottom: 1.5rem solid transparent;
                    }
                }
            }
            .floating.label {
                padding: 0.25em 0.5em;
            }
        }
    }
}

/* Mobile nav */

.mobile-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 3px 0 0 0;
    border-radius: 0 !important;
    border: 0 !important;
    box-shadow: none !important;
    background-color: transparent !important;
    background-size: 101% 102% !important;
    background-position: top center !important;
    background-repeat: no-repeat !important;
    z-index: 33;
    .item {
        flex-grow: 1 !important;
        justify-content: center;
        &:before {
            content: '';
            width: 0 !important;
            background: none !important;
        }
        &.clientLogoMobile {
            box-shadow: inset -1px 0px 0px 0px rgba(0, 0, 0, 0.1),
                inset 1px 0px 0px 0px rgba(0, 0, 0, 0.1);
        }
        &:hover {
            background: none !important;
        }
        &.active {
            background: none !important;
            svg {
                g {
                    path,
                    circle {
                        stroke: #00b06d;
                    }
                }
            }
        }
        svg {
            position: relative;
            margin-top: 8px;
            @media (max-width: 320px) {
                min-width: 20px;
                max-width: 20px;
            }
        }
    }
}

/* Nav toggle */
.sideBarNavToggle {
    position: relative;
    left: -13px;
    bottom: 0.75rem;
    display: inline-block;
    width: 30px;
    height: 30px;
    cursor: pointer;
    pointer-events: auto;
    border: 0;
    background: none;
    box-shadow: none;
    -webkit-appearance: none !important;
    z-index: 103;

    .toggleWrap {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        span {
            position: absolute;
            display: block;
            height: 2px;
            width: 14px;
            background: #4a4a4a;
            transform: rotate3d(0, 0, 0);
            transform-origin: center;
            transition: all ease 0.4s;

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                right: 0;
                left: auto;
            }
            &:nth-child(1) {
                top: 13px;
                background: #fff;
            }
            &:nth-child(2) {
                top: 19px;
                right: 0;
                width: 10px;
                background: #fff;
            }
            &:nth-child(3) {
                top: 25px;
                background: #fff;
            }
        }
    }
    &.active {
        span {
            background: #fff !important;
            &:nth-child(1) {
                transform: rotate3d(0, 0, 1, 225deg);
                top: 16px;
            }
            &:nth-child(2) {
                opacity: 0;
                right: -10em;
            }
            &:nth-child(3) {
                transform: rotate3d(0, 0, 1, 135deg);
                top: 16px;
            }
        }
    }
}

/* Sidebar Nav component */
.sidebarNav {
    position: fixed;
    left: -300px;
    top: 0;
    bottom: 0;
    width: 300px;
    overflow: hidden;
    background-image: linear-gradient(
        180deg,
        #00b16c 0%,
        #3ebc57 24%,
        #aace37 81%
    );
    padding: 2px 0 0 0;
    z-index: 102;
    transition: left 0.3s ease;
    &.active {
        left: 0;
        padding-bottom: 30px;

        .navContent {
            height: 100%;
            padding: 3rem 0 0 0;
            & > .menu {
                width: 100%;
                border-radius: 0;
                border: 0;
                background: none;
                box-shadow: none !important;
                .item {
                    text-align: left;
                    color: #fff;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    &:hover {
                        color: #fff;
                    }
                    svg {
                        display: inline-block;
                        margin-right: 1rem;
                    }
                }
            }
        }
    }
}
.sideBarDimmer {
    display: block;
    position: fixed;
    top: 0;
    left: -100%;
    bottom: 0;
    width: 0;
    /*pointer-events: none;*/
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 101;
    opacity: 0;
    transition: opacity ease 0.3s;
    &.active {
        left: 0;
        width: 100%;
        opacity: 1;
        z-index: 101;
    }
}
