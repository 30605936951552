/* Drawer component */
.drawer {
    position: fixed;
    left: 0;
    bottom: 40px;
    width: 100%;
    overflow: hidden;
    background-image: linear-gradient(0deg, #f5f5f5 0%, #fff 24%, #fff 81%);
    padding: 2px 0 0 0;
    z-index: 3;
    transition: height 0.3s ease;

    .drawer-header {
        background-color: #eaeaea;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        height: 50px;
        padding: 1rem;

        .ui.header {
            color: #00b06d;
        }

        .button {
            font-weight: 600;
            color: #000 !important;
            text-transform: uppercase;
            background: transparent !important;

            &:focus,
            &:hover,
            &:active {
                color: #000 !important;
                background: transparent !important;
            }
        }
    }

    &.inactive {
        height: 60px;
        .drawer-header {
            .column {
                padding-top: 0;
            }
        }
    }
    &.active {
        height: 400px;
        padding-bottom: 30px;
        .drawer-header {
        }
        .drawer-content {
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
            height: 340px;
            padding: 1rem 1rem 30px 1rem;
            & > div {
                width: 100%;
            }
        }
    }
}
.drawerDimmer {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    opacity: 0;
    transition: opacity ease 0.3s;
    &.active {
        pointer-events: all;
        opacity: 1;
        z-index: 2;
    }
}
