.ui.grid.select-calendar {
    margin: 0;
    justify-content: space-between;
    i.icon {
        display: none;
    }
    .wide.column {
        padding: 0 !important;
        margin: 0 !important;
    }
}
