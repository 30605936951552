/* Report placeholder */
.report-placeholder {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    font-weight: 600;
    color: #7c7c7c;
    text-transform: uppercase;
    background-color: #f0f0f0;
    border-radius: 6px;
    margin: 0 !important;
    min-height: 200px;
    margin-bottom: 2rem !important;
    @media (min-width: 820px) {
        margin: 0 !important;
        min-height: 400px;
    }
}
