/* modals */
.ui.modal {
  h3,h5 {
    margin: 0 !important;
    font-size: 1.2rem;
  }
  h5 {
    font-weight: 400;
    color: #999;
    margin-right: 0;
    margin-bottom: 0;
    }
}
.flagDimmer {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  opacity: 0;
  -webkit-transition: opacity ease .3s;
  -o-transition: opacity ease .3s;
  transition: opacity ease .3s;

  &.active {
    opacity: 1;
  }
}
.ui.modals.dimmer {
  align-items: center;
  justify-content: center;
  .ui.modal {
    margin: 0 !important;
    top: auto !important;
    left: auto !important;
  }
}
