/* Report Component */
.reportGrid {
    @media (max-width: 819px) {
        -webkit-overflow-scrolling: touch;

        .ui.grid.reportGridContainer {
            display: flex;
            align-items: stretch;
            padding-bottom: 70px !important;
            & > .column {
                position: relative;
                padding-bottom: 2rem !important;

                &.grid.column {
                    margin-left: 0 !important;
                    margin-right: 0 !important;
                }
                /* scroll-snap-align: center;
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    display: block;
                    width: 0;
                    border-right: 1px solid #ddd;
                }*/
            }
        }
        .report-chart {
            width: 80%;
            margin: 0 auto;
        }
    }
    @media (max-width: 819px) and (max-height: 600px) {
        padding-bottom: 12rem;
    }
    .grid.column {
        .column:not(.dividerCol) {
            margin-bottom: 2rem;
            @media (min-width: 820px) {
                margin-bottom: 4rem;
                display: inline-block !important;
            }
        }
    }
}

/* Charts */
svg.recharts-surface {
    display: inline-block;
    vertical-align: middle;
}
svg.recharts-surface + span {
    margin-left: 0.5rem;
    display: inline-block;
    vertical-align: middle;
}

svg g.totalWraper {
    transform: translate(50%, 0);

    .donut-total {
        font-size: 2.2rem;
        font-weight: 600;
        transform: translate(0, 0);
    }
    .donut-total-desc {
        font-size: 0.9rem;
        transform: translate(-10%, 0);
    }
}

.report-pagination {
    margin-top: 1rem;

    .ui.selection.dropdown {
        padding: 0.5em 1.5em 0.5em 0.5em;
        min-width: 5em;
        min-height: 2em;
        margin-right: 1rem;

        & > .dropdown.icon {
            top: 0;
            right: 0;
            z-index: 3;
            margin: 0;
            padding: 0.5em 1rem;
        }
    }
}

.ui.horizontal.divider:before,
.ui.horizontal.divider:after {
    top: auto !important;
}
