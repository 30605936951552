.global-header {
    background-image: linear-gradient(
        90deg,
        #00b16c 0%,
        #3ebc57 24%,
        #aace37 81%
    );
    border: 0;
    border-bottom: 2px solid #00b06d;
    box-shadow: none;
    height: 50px;
    @media (min-width: 820px) {
        height: 70px;
    }
    padding: 0 !important;

    svg#logo {
        #logo-type {
            #ah,
            #tagline,
            #tm {
                opacity: 1;
                transition: all ease 0.3s;
            }
            #arrow {
                transition: transform ease 0.3s;
            }
        }
    }
    @media (max-width: 819px) {
        svg#logo {
            #logo-type {
                #ah,
                #tagline,
                #tm {
                    visibility: hidden;
                    width: 0;
                    height: 0;
                    opacity: 0;
                    transform: translate(0, 0);
                }
                #arrow {
                    transform: translate(0, 12px);
                }
            }
        }
    }

    .user-comms {
        position: relative;
        bottom: 2px;
        height: 50px;
        display: inline-flex !important;
        flex-direction: row !important;
        align-items: center;
        justify-content: flex-start;

        @media (min-width: 820px) {
            height: 70px;
            background-color: #fff;
            &:before {
                position: absolute;
                left: -60px;
                bottom: 0;
                content: '';
                width: 60px;
                background: none;
                border-top: 72px solid transparent;
                border-right: 50px solid #fff;
                border-left: 0 solid transparent;
                border-bottom: 0 solid transparent;
                z-index: 1;
            }
        }
        .menu.user-menu {
            margin: 0;
            padding: 0.25rem 0;
            justify-content: flex-end;

            .item {
                &.userProfile {
                    @media (min-width: 820px) {
                        padding-left: 2rem !important;
                        padding-right: 2rem !important;
                    }
                }
                .ui {
                    &.avatar.image {
                        margin: 0 0.25em;
                    }
                    &.label {
                        &.floating {
                            padding: 0.18em 0.35em !important;
                            top: 5px !important;
                            left: auto !important;
                            right: 12px !important;
                            margin: 0 !important;
                        }
                    }
                }
                .dropdown {
                    &.active,
                    &:hover {
                        background: transparent !important;
                        @media (max-width: 819px) {
                            .menu.visible {
                                right: 0 !important;
                                left: auto !important;
                            }
                        }
                    }
                    .icon {
                        &.bell.outline {
                            width: 24px !important;
                            height: 24px !important;
                            &:before {
                                content: '';
                                display: block;
                                background: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="18" height="23" viewBox="0 0 18 23"%3E%3Cpath fill="none" stroke="%23FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6.72653792 18.98022C6.95420106 19.7557556 7.66576598 20.2884625 8.47402713 20.2884625 9.28228829 20.2884625 9.99385321 19.7557556 10.2215164 18.98022M8.4718455 2.61796138L8.4718455.654490344M8.4718455 2.61796138C12.0864959 2.61796138 15.0167489 5.54821446 15.0167489 9.16286481 15.0167489 15.3115834 16.3257296 16.3622586 16.3257296 16.3622586L.617961375 16.3622586C.617961375 16.3622586 1.92694206 14.6902539 1.92694206 9.16286481 1.92694206 5.54821446 4.85719514 2.61796138 8.4718455 2.61796138z" transform="translate(.47 1.244)"/%3E%3C/svg%3E') !important;
                                width: 24px;
                                height: 24px;
                                background-position: top center !important;
                                background-repeat: no-repeat !important;
                                background-size: contain !important;
                            }
                            @media (min-width: 820px) {
                                &:before {
                                    content: '';
                                    display: block;
                                    background: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="18" height="23" viewBox="0 0 18 23"%3E%3Cpath fill="none" stroke="%23767676" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6.72653792 18.98022C6.95420106 19.7557556 7.66576598 20.2884625 8.47402713 20.2884625 9.28228829 20.2884625 9.99385321 19.7557556 10.2215164 18.98022M8.4718455 2.61796138L8.4718455.654490344M8.4718455 2.61796138C12.0864959 2.61796138 15.0167489 5.54821446 15.0167489 9.16286481 15.0167489 15.3115834 16.3257296 16.3622586 16.3257296 16.3622586L.617961375 16.3622586C.617961375 16.3622586 1.92694206 14.6902539 1.92694206 9.16286481 1.92694206 5.54821446 4.85719514 2.61796138 8.4718455 2.61796138z" transform="translate(.47 1.244)"/%3E%3C/svg%3E') !important;
                                    width: 24px;
                                    height: 24px;
                                    background-position: top center !important;
                                    background-repeat: no-repeat !important;
                                    background-size: contain !important;
                                }
                            }
                        }
                    }
                }

                svg {
                    path {
                        stroke: #fff;
                    }
                    @media (min-width: 820px) {
                        path {
                            stroke: #767676;
                        }
                    }
                }
                .notificationAlert {
                    max-width: 280px;
                    .item {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}
.payerBrand {
    img {
        max-height: 30px;
        width: auto;
    }
}
