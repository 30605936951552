/* Support and Chat */
.ui.comments.chat {
    background-color: #fff;
    padding: 1rem;
    border-radius: 0.25rem;
    border: 1px solid rgba(34, 36, 38, 0.22);

    & + .ui.form {
        margin-top: -1rem;

        .ui.button {
            display: inline-block;
        }
    }
    .comment {
        margin-bottom: 1rem;
        .text {
            padding: 1rem;
            background-color: #f5f5f5;
            border-radius: 0.25rem;
        }

        &.support-comment {
            .author {
                color: #00b06d;
            }
            .text {
                padding: 1rem;
                background-color: #f2f7e9;
                border-radius: 0.25rem;
            }
        }
        &.user-comment {
            padding-left: 1rem;
        }
    }

    .reply.form textarea {
        height: auto !important;
    }
}
.rowJustify {
    display: flex;
    justify-content: space-between;
    .ui.button {
        margin: 0;
    }
}
