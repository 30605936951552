/* Account Styles */
.account-avatar {
    img {
        width: 100%;
        height: auto;
    }
    img + button {
        margin-top: 0.5rem !important;
        width: 100%;
    }
}
@media (max-width: 819px) {
    .account-avatar {
        position: absolute !important;
        top: 0;
        right: 0;
        order: 2;
        max-width: 120px !important;
    }
    .account-details {
        padding-top: 0 !important;
        padding-bottom: 100px !important;

        h2.ui.dividing.header {
            margin-bottom: 0.5rem !important;
        }

        .ui.stackable.grid > .row {
            padding-bottom: 0 !important;
            & > .column {
                padding-top: 0 !important;
                padding-bottom: 0 !important;

                .form {
                    padding-top: 1rem;
                }
            }
        }
        .button {
            width: 100%;
            margin-bottom: 1rem;
        }
        .ui.header {
            border-bottom: 0;
            padding-bottom: 0;
            margin-bottom: 0 !important;
        }
        .ui.divider {
            padding-left: 0;
            padding-right: 0;
            margin-left: 0 !important;
            margin-right: 0 !important;
            width: 100%;
        }
    }
}
