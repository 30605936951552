.ui.container.Dashboard-Header {
    width: 100%;
    .column.title {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        span {
            margin-right: 1rem;
        }
    }
}
