/**
Global CSS
**/
@import './assets/styles/semantic.css';
@import './assets/styles/colors.scss';
@import './assets/styles/typography.scss';
@import './assets/styles/tables.scss';
@import './assets/styles/helper.scss';
@import './assets/styles/forms.scss';
@import './assets/styles/nav.scss';
@import './assets/styles/app-header.scss';
@import './assets/styles/divider.scss';
@import './assets/styles/modal.scss';
@import './assets/styles/feed.scss';
@import './assets/styles/event.scss';
@import './assets/styles/drawer.scss';
@import './assets/styles/report-placeholder.scss';
@import './assets/styles/accordion.scss';
@import './assets/styles/lists.scss';
@import './assets/styles/dropdown.scss';
@import './assets/styles/login.scss';
@import './assets/styles/patients.scss';
@import './assets/styles/reports.scss';
@import './assets/styles/account.scss';
@import './assets/styles/chat.scss';
@import './assets/styles/visibility.scss';

* {
    box-sizing: border-box;
}
.full-height {
    height: 100vh;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    overflow: hidden;
}
.dimmerWrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.ui.segment[class*='bottom attached'] {
    min-height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
.pushable > .pusher {
    background: inherit;
    flex-grow: 1;
}
div.copyright {
    text-align: center;
    color: #777;
    font-size: 8pt;
    padding: 1rem;
    margin-top: 0;
    border-top: 1px solid #ddd;
    @media (max-width: 819px) {
        display: none;
    }
}
