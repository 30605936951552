/* Color Palette */
$primary: #00b06d;
$secondary: #8fcc22;
$gray: #727477;
$alert: #db2828;
$info: gold;

/* Color */
.greenColor {
    color: #00b06d !important;
}
.greenLightColor {
    color: #8fcc22 !important;
}
.grayColor {
    color: #727477 !important;
}
.whiteColor {
    color: #fff !important;
}
.alert {
    color: $alert;
}
.info {
    color: $info !important;
}

/* Background Color */
.greenBG {
    background-color: #00b06d !important;
}
.shaded {
    background-color: #f2f2f2 !important;
}
.whiteBG {
    background-color: #fff !important;
}
.lightGrayBG {
    background-color: #f7f7f7 !important;
}
.greenBrightBG {
    background-color: #f2f7e9 !important;
}
.grayBG {
    background-color: #727477 !important;
}
