/* Visibility Classes without Grid */

@media (max-width: 819px) {
  .tablet-up-show, .ui.grid .row .column.tablet-up-show {
    display: none !important;
  }
}
@media (min-width: 820px) {
  .mobile-show, .ui.grid .row .column.mobile-show {
    display: none !important;
  }
}
